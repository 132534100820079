// scss file that includes all variables and classes
@import "framework.scss";

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Roboto';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


body{
    // background-color: #00314a;
}

input[type='submit']{
    cursor: pointer;
}

a{
    text-decoration: none;
    color: black;
}


.container{ 
    width: 100%;

    .top-level-container{
        margin-bottom: 20px;
        display: flex;
        width: 100%;
        border-bottom: 1px solid gainsboro;
        height: 66px;
        padding: 12px 20px 12px 20px;

        .title{
            font-size: 20px;
            display: flex;
            align-items: center;
        }

        a{
            margin-left: auto;
            border: 2px solid #1976d2;
        }
    }

    .main-content-page{
        width: 1290px;
        margin: 30px auto 30px auto;
        padding: 0 20px;
    }
}

/** Media Queries */
@media only screen and (max-width: 1500px){
    .container{
        .main-content-page{
            width: 90%;
        }
        
    }
}