/** ---- Variables ----- */
$main_color: black;
$main_logo_color: #0099cc;
$secondary_logo_color: #f45110;
$thirdly_logo_color: #00314a;
$grey_color: #555555;
$primary-font: 'Inter', 'Open Sans', 'Arial', sans-serif !default;

/** ---- Classes ----- */
%long-text-overflow{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/** ---- Fonts ----- */
// Material ui Roboto Fonts
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';