// scss file that includes all variables and classes
@import "../../assets/scss/framework.scss";


.login-form{
    background-color: #00314a;

    form{
        height: 100vh;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .form-inner{
        width: 500px;
        background-color: white;
        padding: 20px 20px 40px 20px;
        border-radius: 8px;
        position: relative;

        .form-title{
            margin-bottom: 20px;
        }

        .form-group{
            width: 75%;
            display: inline-grid;
            position: relative;
            margin-top: 10px;

            .css-xilmwd-MuiPaper-root-MuiAlert-root{
                background-color: unset;
                padding: 0 0 0 5px;
            }
        }

        .form-group-errors{
            margin-top: 20px;
            margin-bottom: 20px;

            .form-group{
                display: none;
                background-color: #e7e7e7;
                border-radius: 6px;
                width: 75%;
                margin-left: auto;
                margin-right: auto;
                padding: 5px 10px;
                font-size: 13px;
                color: #c50000;
                line-height: 15px;
    
                &.error{
                    display: block;
                }
            }
        }

        

        input[type='text'],
        input[type='password']{
            width: 100%;
            border: 1px solid #dcd9d9;
            outline: none;
            padding: 12px 20px 12px 60px; 
            border-radius: 6px;
            font-size: 15px;
        }

        button{
            width: 75%;

            &:hover{
                box-shadow: 0 0 10px RGBA(0 49 74 / 50%);
                background: $thirdly_logo_color;
                transition: all 0.3s ease;
            }
        }   

        .input-label-section{
            width: 50px;
            position: absolute;
            height: 43px;
            border-right: 1px solid #dcd9d9;
            top: 0;
            left: 0;
            align-items: center;
            justify-content: center;
            display: flex;

            svg{
                width: 25px;
                height: 25px;
                background-repeat: no-repeat;
            }
        }

        .form-group-email{
            .input-label-section{
                svg.icon{
                    background-image: url(../../assets/images/at_email.svg);
                }
                svg.icon-error{
                    background-image: url(../../assets/images/cancel.svg);
                }
                svg.icon-success{
                    background-image: url(../../assets/images/accept.svg);
                }
            }
        }

        .form-group-password{
            .input-label-section{
                svg.icon{
                    background-image: url(../../assets/images/password.svg);
                }
                svg.icon-error{
                    background-image: url(../../assets/images/cancel.svg);
                }
                svg.icon-success{
                    background-image: url(../../assets/images/accept.svg);
                }
            }
        }
    }
}


/** Media Queries */
@media only screen and (max-width: 480px){
    .login-form{

        .form-inner{
            width: 90%;

            .form-group,
            .form-group-errors .form-group,
            button{
                width: 90%;
            }
        }
    }
}

@media only screen and (max-width: 420px){
    .login-form{

        .form-inner{

            .form-group,
            .form-group-errors .form-group,
            button{
                width: 100%;
            }
        }
    }
}